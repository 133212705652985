import React from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Breadcrumb } from 'antd';
import cx from "classnames";

import * as Icons from "assets/icons";

import { useSingle } from 'modules/functionRegister/hooks';
import { useDownload } from 'modules/file/mutations';

import * as Grid from "components/Grid";

import Button from "components/Button";
import Spacer from "components/Spacer";
import Container from "components/Container";

import DigitizationStatus from './components/DigitizationStatus';

import cls from './FunctionView.module.scss';

const FunctionView = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();

  const { item } = useSingle({ id });

  const { mutate } = useDownload();

  const download = ({ name, uuid }) => {
    mutate({ uuid }, {
      onSuccess: ({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      }
    });
  }

  return (
    <Container>
      <Spacer size={35} />

      <Breadcrumb separator=">">
        <Breadcrumb.Item href='/'>{t('home')}</Breadcrumb.Item>
        <Breadcrumb.Item href='/function-register'>{t('function_register')}</Breadcrumb.Item>
        <Breadcrumb.Item>{t('function')}</Breadcrumb.Item>
      </Breadcrumb>

      <Spacer size={32} />

      <div className={cls.content}>
        <div className={cls.flex}>
          <div className={cls.left}>
            <span className={cls.title}>{t('function_name')}</span>
            <strong className={cls.largeTitle}>{item.function.name[i18n.language]}</strong>
          </div>

          <div className={cls.right}>
            <span className={cls.title}>{t('function_code')}</span>
            <strong className={cls.largeTitle}>{item.function.code}</strong>
          </div>
        </div>

        <Spacer size={32} />

        <div className={cls.right} style={{ marginLeft: "auto" }}>
          <span className={cls.title}>{t('private_sector')}</span>
          <div className={cx(cls.personal, item.personal && cls['active'])}>
            {item.personal ? t('personal_transferred') : t('personal_not_transferred')}
          </div>
        </div>
      </div>

      <div className={cls.center}>
        <Grid.Row gutter={[24, 32]}>
          <Grid.Col xs={16}>
            <div className={cls.card}>
              <div className={cls.cardContent}>
                <div className={cls.cardIcon}>
                  <Icons.Organization />
                </div>

                <div>
                  <span className={cls.cardKey}>{t('involved_organization')}</span>
                  <strong className={cls.cardValue}>{item.organization.name[i18n.language]}</strong>

                  <span className={cls.cardKey}>{t('department_name')}</span>
                  <strong className={cls.cardValue}>{item.departmentName}</strong>

                  <ul className={cls.cardDegrees}>
                    {item.functionDegree.map(degree => (
                      <li key={degree}>{t(degree)}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </Grid.Col>

          <Grid.Col xs={8}>
            <div className={cls.card}>
              <div className={cls.cardContent}>
                <div className={cls.cardIcon}>
                  <Icons.Dollar />
                </div>

                <div>
                  <span className={cls.cardKey}>{t('source_of_directed_funds')}</span>
                  <strong className={cls.cardValue} style={{ marginBottom: 0 }}>{item.source}</strong>
                </div>
              </div>

              <Spacer size={24} />

              {/*<div>*/}
              {/*  <ul className={cls.flex}>*/}
              {/*    <li>*/}
              {/*      <span className={cls.cardCountKey}>{t('fund')}</span>*/}
              {/*      <strong className={cls.cardCountValue}>{item.fund} {t(item.currency)}</strong>*/}
              {/*    </li>*/}

              {/*    <li>*/}
              {/*      <span className={cls.cardCountKey}>{t('appropriation_rate')}</span>*/}
              {/*      <strong className={cls.cardCountValue}>{item.percentage}%</strong>*/}
              {/*    </li>*/}
              {/*  </ul>*/}
              {/*</div>*/}
            </div>
          </Grid.Col>

          <Grid.Col xs={16}>
            <div className={cls.card}>
              <div className={cls.cardContent}>
                <div className={cls.cardIcon}>
                  <Icons.File />
                </div>

                <div>
                  <span className={cls.cardKey}>{t('regulatory_legal_document_name')}</span>
                  <strong className={cls.cardValue}>{item.basisName[i18n.language]}</strong>

                  <div className={cls.flex}>
                     <div style={{ marginRight: 40 }}>
                       <span className={cls.cardKey}>{t('regulatory_legal_document_date')}</span>
                       <strong className={cls.cardValue} style={{ marginBottom: 0 }}>{item.basisDate}</strong>
                     </div>

                    <div style={{ marginRight: 40 }}>
                      <span className={cls.cardKey}>{t('regulatory_legal_document_number')}</span>
                      <strong className={cls.cardValue} style={{ marginBottom: 0 }}>{item.basisNumber}</strong>
                    </div>

                    <div style={{ marginRight: "auto" }}>
                      <span className={cls.cardKey}>{t('regulatory_legal_document_link')}</span>
                      <strong className={cls.cardValue} style={{ marginBottom: 0 }}>
                        <a href={item.function.link} target='_blank' rel="noreferrer">{t('regulatory_legal_document_link_go')}</a>
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid.Col>

          <Grid.Col xs={8}>
            <div className={cls.card}>
              <div className={cls.cardContent}>
                <div className={cls.cardIcon}>
                  <Icons.Persons />
                </div>

                <div>
                  <span className={cls.cardKey}>{t('employees_and_vacancies')}</span>

                  <Spacer size={16} />

                  <div>
                    <span className={cls.cardKey}>{t('employee')}</span>
                    <strong className={cls.cardCountValue}>{item.dedicatedEmployer}</strong>
                  </div>
                </div>
              </div>
            </div>
          </Grid.Col>

          <Grid.Col xs={24}>
            <div className={cls.card}>
              <div className={cls.addition}>
                <Icons.Mechanism />

                <strong className={cls.additionTitle}>{t('function_mechanism')}</strong>
              </div>

              <div>
                <span className={cls.cardKey}>{t('mechanism_name')}</span>
                <strong className={cls.cardValue}>{item.mechanism[i18n.language]}</strong>

                <span className={cls.cardKey}>{t('regulatory_legal_document_name')}</span>
                <strong className={cls.cardValue}>{item.basisName[i18n.language]}</strong>

                <div className={cls.flex}>
                  <div style={{ marginRight: 40 }}>
                    <span className={cls.cardKey}>{t('regulatory_legal_document_date')}</span>
                    <strong className={cls.cardValue} style={{ marginBottom: 0 }}>{item.basisDate}</strong>
                  </div>

                  <div style={{ marginRight: "auto" }}>
                    <span className={cls.cardKey}>{t('regulatory_legal_document_number')}</span>
                    <strong className={cls.cardValue} style={{ marginBottom: 0 }}>{item.basisNumber}</strong>
                  </div>
                </div>
              </div>
            </div>
          </Grid.Col>

          {item.task.id && (
            <Grid.Col xs={24}>
              <div className={cls.card}>
                <div className={cls.addition}>
                  <Icons.DoneWork />

                  <strong className={cls.additionTitle}>{t('done_works')}</strong>
                </div>

                <div>{item.task.title[i18n.language]}</div>

                {item.task.links.map(({ name, link }, index) => (
                   <div style={{ marginTop: 10 }} key={index}>
                     <a href={link} target='_blank' rel="noreferrer">{name}</a>
                   </div>
                ))}

                <div className={cls.additionButton}>
                  <Button
                     background='blue'
                     color='white'
                     border='blue'
                    onClick={() => {
                      download({ name: 'file', uuid: item.task.file.uuid });
                    }}
                  >
                    {t('download')} <Icons.Download />
                  </Button>
                </div>
              </div>
            </Grid.Col>
          )}

          <Grid.Col xs={24}>
            <DigitizationStatus
              activityType={item.activityType}
              digitizationStatus={item.digitizationStatus}
              functionUsers={item.functionUsers}
              reestrProjects={item.reestrProjects}
              presentationStatus={item.presentationStatus}
              otherSystem={item.otherSystem}
            />
          </Grid.Col>
        </Grid.Row>
      </div>
    </Container>
  )
}

export default FunctionView;